/* Navbar.css */
.navbar {
  background: linear-gradient(45deg, #0070e7, #00a2e8);
  padding: 0rem 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease-in-out;
}

.navbar:hover {
  background: linear-gradient(45deg, #0056b3, #00a2e8);
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-logo {
  width: 120px;
  max-height: 50px;
  border-radius: 6px;
}

.navbar-brand span {
  margin-left: 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #ffffff; /* White color for the brand text */
}

.nav-link {
  font-size: 1rem;
  color: #ffffff; /* White color for the links */
  transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.nav-link:hover {
  color: #ffff8d; /* Yellow color on hover */
  transform: scale(1.1);
}

.navbar-toggler {
  border: none;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' linecap='round' linejoin='round' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-collapse {
  justify-content: flex-end;
}

.nav-item {
  margin-left: 0.5rem;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

/* Responsive Design */
@media (max-width: 767px) {
  .navbar-nav {
    text-align: center;
  }
  
  .nav-item {
    margin-left: 0;
    margin-bottom: 0.5rem;
  }
  
  .navbar-brand span {
    font-size: 1rem;
  }
  
  .navbar-logo {
    height: 35px;
  }
}



