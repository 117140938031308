.contact-container {
    font-family: 'Roboto', sans-serif;
    background-color: #f8f9fa;
}

.contact-header {
    position: relative;
    background-color: #ffffff;
}

.cover-image {
    background-size: cover;
    background-position: center;
    height: 300px; /* Adjust the height as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
}

.cover-text {
    text-align: center;
}

.cover-text h1 {
    font-size: 3rem; /* Adjust as needed */
    margin: 0;
}

.cover-text p {
    font-size: 1.5rem; /* Adjust as needed */
}

/* contact details */
.contact-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 50px;
}

.contact-details {
    flex: 1;
    min-width: 200px;
    margin: 10px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 14px 18px rgba(0, 0, 0, 0.1);
}

.contact-details p {
    color: #333333;
    font-weight: 300;
    text-align: justify;
}

.contact-details p a {
    text-decoration: none;
    color: #333333;
}

/* contact map */
.contact-map h2 {
    text-align: center;
}

.contact-map {
    background-color: #f1f3f5;
    padding: 20px;
}

iframe {
    border: none;
}

h2 {
    color: #666;
    margin-bottom: 15px;
    font-weight: 500;
}

/* Media Queries */
@media (max-width: 1200px) {
    .contact-info {
        padding: 30px;
    }
}

@media (max-width: 992px) {
    .contact-info {
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .contact-details {
        width: 100%;
        margin-bottom: 20px;
    }
}

@media (max-width: 768px) {
    .cover-text h1 {
        font-size: 2rem;
    }

    .cover-text p {
        font-size: 1rem;
    }
}

@media (max-width: 576px) {
    .contact-details {
        width: 100%;
        padding: 15px;
    }

    .contact-info {
        padding: 10px;
    }

    .contact-details h2 {
        font-size: 1.5rem;
    }

    .contact-details p {
        font-size: 1rem;
    }
}
