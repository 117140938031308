@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

/* General Styling */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* CSS for Footer */
footer {
  background-color: #424242;
  padding: 40px 20px;
  color: #ffffff;
  text-align: center;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.footer-content,
.footer-links,
.footer-about,
.hero {
  margin: 20px;
  flex: 1 1 200px;
}
p{
  text-align: center;
}

.footer-content h3,
.footer-links h3,
.footer-about h3,
.hero h3 {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 700;
}

.footer-content p,
.footer-links p,
.footer-about p {
  margin: 5px 0;
  font-size: 14px;
}

.footer-container .footer-content p a:link,
.footer-container .footer-content p a:visited,
.footer-container .footer-content p a:hover,
.footer-container .footer-content p a:active,
.footer-container .footer-links p a:link,
.footer-container .footer-links p a:visited,
.footer-container .footer-links p a:hover,
.footer-container .footer-links p a:active,
.footer-about p a:link,
.footer-about p a:visited,
.footer-about p a:hover,
.footer-about p a:active {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-container .footer-content p a:hover,
.footer-container .footer-links p a:hover {
  color: #BDBDBD;
}

.footer-bottom {
  border-top: 1px solid #444;
  padding-top: 10px;
  margin-top: 10px;
}

.hero {
  background: inherit;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.social-icons a {
  width: 40px;
  height: 40px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  margin: 0 10px;
  position: relative;
  overflow: hidden;
  transition: transform 0.5s, box-shadow 0.5s;
}

.social-icons a .fab,
.social-icons a .fas {
  line-height: 40px;
  font-size: 20px;
  z-index: 10;
  position: relative;
}

.social-icons a::after {
  content: '';
  width: 100%;
  height: 100%;
  top: -90px;
  left: 0;
  background: linear-gradient(-35deg, lightblue, #fdfdfd);
  position: absolute;
  transition: 0.5s;
  z-index: -1; /* Ensure gradient is below icons */
}

.social-icons a:hover::after {
  top: 0;
}

.social-icons a:hover {
  transform: scale(1.2);
  box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.1);
}

.social-icons a.email:hover {
  color: #d44638;
}

.social-icons a.facebook:hover {
  color: #3b5998;
}

.social-icons a.twitter:hover {
  color: #1da1f2;
}

.social-icons a.linkedin:hover {
  color: #0077b5;
}

.social-icons a.instagram:hover {
  color: #e1306c;
}

.footer-copy {
  padding-top: 20px;
  border-top: 1px solid #444;
}

.footer-copy h4 {
  margin: 10px 0;
  font-size: 14px;
  color: #bbb;
}

/* Responsive Design */
@media (max-width: 767.98px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-content,
  .footer-links,
  .footer-about,
  .hero {
    margin: 10px 0;
  }
}