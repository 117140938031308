/* General Styling */
body {
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #f8f9fa;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

.container-fluid {
  padding: 20px;
}

.text-center {
  text-align: center;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.mb-1 {
  margin-bottom: 0.20rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

/* Business Section */
.business-section {
  background-color: #ffffff;
  padding: 100px 100px;
  /* border-bottom: 1px solid #ddd; */
  
}

.business-title {
  font-weight: 900;
  color: #007bff;
}

.business-subtitle {
  font-weight: 600;
  color: #333;
}

.business-subtitleline {
  font-weight: 300;
  font-size: large;
  /* letter-spacing: 0.3rem; */
  color: #333;
}

/* Intro Cards css */
/* IntroCard.css */

.intro-cards {
  padding: 40px 0;
}

.slogan{
  line-height: 2rem;
  letter-spacing: 0.2rem;
  
}
.intro-cards .card {
  border: none;
  background-color: #fff;
  box-shadow: 0 14px 18px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.intro-cards .card img {
  border-radius: 10px;
}

.intro-cards .card .card-body {
  padding: 20px;
  min-height: 200px;
  
}

.logo{
  /* height: 450vh; */
  border-radius: 40%;
  /* border: #000000 solid; */
  justify-content: center;
}

.intro-cards .card .card-title {
  font-weight: 500;
  color: #4CAF50;
}

.intro-cards .card .card-text {
  color: #212121;
  font-weight: 300;
  text-align: justify;
}

.intro-cards .card .card-text small {
  color: #212121;
}



/* service-section css */

.service-section {
  background-color: #f1f3f5;
  padding: 30px 20px;
  flex: content;
}

.service-title {
  font-size: 32px;
  /* Increased the size for better emphasis */
  font-weight: 500;
  color: #007bff;
  margin-bottom: 10px;
}

.service-subtitle {
  font-size: 24px;
  /* Larger subtitle for emphasis */
  font-weight: 450;
  color: #555;
  margin-bottom: 20px;
  /* Adjusted spacing from the title */
}

.service-description {
  color: #666;
  line-height: 1.8;
  /* Increased line height for readability */
  max-width: 700px;
  margin: 0 auto 30px;
  font-weight: 300;
  font-size: 1.2rem;
  /* Slightly larger font size */
  list-style-type: none;
  padding-left: 20px;
  font-size: 1.1rem;
  /* Slightly larger font size for better readability */
  line-height: 1.4;
  /* Increased line height for readability */
  color: #333;
  /* Darker color for contrast */
}

.service-description li {
  margin-bottom: 15px;
}

.service-description li::marker {
  color: #000000;
}

.nowrap {
  display: inline;
  /* Ensures the text doesn’t break onto the next line */
  white-space: nowrap;
  /* Prevents text from wrapping */
}



/* Infocard css*/

.info-card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s ease;
  flex: 1 0 30%;
  /* Flex item with initial basis of 30% */
  margin: 20px;
  margin-top: 25px;
}

.info-card:hover {
  transform: scale(1.03);
  /* Slightly enlarge the card on hover */
}

.info-card-image {
  height: 200px;
  /* Set the height for the image */
  background-size: cover;
  background-position: center;
  /* background-image: url('/Users/md/News/src/component/agro website.webp'); */
  position: relative;
  transition: transform 0.3s ease;
  /* Add transition for image */
}

.info-card:hover .info-card-image {
  transform: scale(1);
  /* Zoom in the image on hover */
}

.info-card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
  opacity: 0;
  height: 100%;
  overflow: hidden;

  transition: opacity 0.3s ease;
}

.info-card:hover .info-card-content {
  opacity: 0.95;
  /* Show the description on hover */
}

.info-card-title {
  font-size: 1.7rem;
  margin-bottom: 10px;
  color: #007bff;
  font-weight: 500;
}

.info-card-description {
  font-size: 1.1rem;
  color: #333;
  text-align: justify;
  font-weight: 300;
}


/* product section */
.products-section {
  padding: 20px 450px;
  background-color: #f8f9fa;
}

.products-title {
  font-size: 32px;
  margin-bottom: 10px;
}

.products-description {
  font-size: 1.2rem;
  margin-bottom: 0px;
  text-align: justify;
  font-weight: 300;
}



/* Responsive Styles  for info-card*/

@media (max-width: 992px) {
  .info-card {
    flex: 1 0 45%;
    /* Two cards per row on medium screens */
    margin: 10px;
  }
}

@media (max-width: 768px) {
  .info-card {
    flex: 1 0 100%;
    /* One card per row on small screens */
    margin: 5px;
  }
}

/* Responsive Styles */

@media (max-width: 1200px) {
  .info-card {
    margin: 10px;
  }
}

@media (max-width: 992px) {
  .info-card {
    margin: 10px;
    max-width: 280px;
    /* Adjust max width */
  }
}

@media (max-width: 768px) {
  .info-card {
    margin: 5px;
    max-width: 250px;
    /* Adjust max width */
  }
}

@media (max-width: 576px) {
  .info-card {
    margin: 5px;
    max-width: 100%;
    /* Full width for smaller screens */
  }
}


/* Responsive Design  for service*/
@media (max-width: 767.98px) {
  .service-title {
    font-size: 28px;
  }

  .service-subtitle {
    font-size: 20px;
  }

  .service-list {
    font-size: 1rem;
    /* Adjusted for smaller screens */
  }

  .service-list li {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .service-title {
    font-size: 32px;
  }

  .service-subtitle {
    font-size: 22px;
  }

  .service-list {
    font-size: 1.05rem;
    /* Slightly larger for medium screens */
  }

  .service-list li {
    margin-bottom: 12px;
  }
}

@media (min-width: 992px) {
  .service-title {
    font-size: 36px;
  }

  .service-subtitle {
    font-size: 24px;
  }

  .service-list {
    font-size: 1.1rem;
  }

  .service-list li {
    margin-bottom: 15px;
  }
}


/* Responsive adjustments  for products*/
@media (max-width: 1200px) {
  .products-section {
    padding: 20px 200px;
  }
}

@media (max-width: 992px) {
  .products-section {
    padding: 20px 100px;
  }
}

@media (max-width: 768px) {
  .products-section {
    padding: 20px 50px;
  }

  .products-title {
    font-size: 1.5em;
  }

  .products-description {
    font-size: 1em;
  }
}

@media (max-width: 576px) {
  .products-section {
    padding: 20px 20px;
  }

  .products-title {
    font-size: 1.2em;
  }

  .products-description {
    font-size: 0.9em;
  }
}

