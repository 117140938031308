.product-info-container {
    font-family: 'Roboto', sans-serif;
    background-color: #f8f9fa;
    display: flex;
    flex-direction: column;
    gap: 0;
}

.product-header {
    position: relative;
    background-color: #ffffff;
    width: 100%;
}

.product-list {
    margin: 15px 10px;
}

.brochure-download {
    position: absolute;
    top: 20px;
    right: 20px;
}

.brochure-download a {
    text-decoration: none;
}

.brochure-download button {
    background: linear-gradient(45deg, #6a11cb, #2575fc);
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 25px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
}

.brochure-download button:hover {
    background: linear-gradient(45deg, #2575fc, #6a11cb);
    transform: translateY(-3px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}


.product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Ensure space between items */
}

.product-container {
    margin: 15px;
    flex: 1 1 calc(50% - 30px); /* Adjusted for two products per row */
    box-sizing: border-box;
}

.product-item {
    border: 1px solid #ccc;
    box-sizing: border-box;
    transition: flex 0.5s ease-in-out; /* Smooth transition for expanding */
}

.product-item.expanded .product-title::after {
    transform: rotate(90deg); /* Rotate arrow when expanded */
}

.product-title {
    padding: 5px;
    margin: 5px;
    background-color: #f7f7f7;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
}

.product-title::after {
    content: '';
    display: inline-block;
    border-left: 9px solid transparent;
    border-bottom: 9px solid #000;
    margin-left: auto; /* Align to the right */
    transition: transform 0.5s ease-in-out;
}

.product-info {
    padding: 10px;
    border-top: 1px solid #ccc;
    max-height: 100px;
    animation: slideDown 0.5s ease-out;
}

.product-item.expanded .product-info {
    max-height: 500px; /* Ensure this is large enough for your table */
}

/* zoom the image  */
.product-info img {
    width: 100px; /* Fixed size for the image */
    height: auto;
    display: block;
    margin-top: 10px;
    transition: transform 0.3s ease-in-out; /* Smooth transition for zoom effect */
}

.product-info img:hover {
    transform: scale(2); /* Scale image to 1.5 times its original size */
}

@keyframes slideDown {
    from {
        max-height: 0;
        opacity: 0;
        padding: 0;
        margin: 0;
    }
    to {
        max-height: 100%; /* Change this value to be more dynamic */
        opacity: 1;
        padding: 10px; /* Add back the padding to the expanded section */
        margin: 5px; /* Add back the margin to the expanded section */
    }
}

/* Additional styles for the table */
.product-info table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.product-info th, .product-info td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.product-info img {
    width: 100px; /* Fixed size for the image */
    height: auto;
    display: block;
    margin-top: 10px;
}

/* Responsive design adjustments */
@media (max-width: 1200px) {
    .product-container {
        flex: 1 1 calc(50% - 30px);
    }
}

@media (max-width: 768px) {
    .product-container {
        flex: 1 1 calc(100% - 20px);
    }
}

@media (max-width: 480px) {
    .product-title {
        font-size: 14px;
        padding: 8px;
    }
    
    .product-info {
        padding: 8px;
    }

    .product-info th, .product-info td {
        padding: 6px;
    }

    .product-info img {
        width: 80px; /* Adjust image size for smaller screens */
    }
}


/* loding ..... */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s infinite linear;
}